@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

html { 
  background-color: #271422;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.topnav-group {
  background-color: #271422;
  overflow: hidden;
}

.topnav {
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 768px) {
  .topnav {    
    margin-left: 5px;
    margin-right: 5px;   
  }               
}

.content-container {
  display: table;
  width: 100%;
}

.content-column {
  display:table-cell;
  width: 50%;
  vertical-align: top;
}

.content-column-mid {
  display:table-cell;
  width: 50%;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .content-column {
    display: block;
    width: 100%;
  }
}

.topnav a {
  float:initial;
  color: #f2f2f2;
  text-align: center;
  padding: 15px;
  margin: 15px;
  font-weight: bold;
  text-decoration: none;
  font-size: 17px;
}

@media only screen and (max-width: 768px) {
  .topnav a {    
    padding: 5px;
    margin: 5px; 
  }               
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: rgb(255,182,222);
  color: #271422;
}

.green-arrow {
  height: 15px;
  filter: invert(31%) sepia(90%) saturate(3488%) hue-rotate(128deg) brightness(90%) contrast(102%);
}

.red-arrow {
  height: 15px;
  filter: invert(8%) sepia(92%) saturate(6264%) hue-rotate(9deg) brightness(92%) contrast(115%);
}

.highlight {
  color: rgb(255,182,222);
  font-weight: bold;
  word-wrap: break-word;
}

.highlight-large {
  color: rgb(255,182,222);
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.upArrow {
  margin-left: 4px;
  color: rgb(0, 142, 33);
  font-weight: bold;
}

.downArrow {
  margin-left: 4px;
  color: rgb(175, 0, 0);
  font-weight: bold;
}

.ticker-table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  margin-bottom: 4px;
  table-layout: fixed;
}

.ticker-column {
  font-weight: normal;
  height: 21px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
}

.ticker-column-logo {
  font-weight: normal;
  height: 21px;
  overflow: hidden;
  text-align: right;
  white-space: nowrap;
}

.ticker-container {
  margin: auto;
  display: grid;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(to left, #fe6d72 1%, #fe6dda 50%, #fe6d72 100%) 
                100% 0 0% 0/1px 0 1px 0 stretch;
}

.ticker-logo {
  height: 20px;
}

.tcontainer {
  width: 100%;
  overflow: hidden; /* Hide scroll bar */
}
 
/* MIDDLE CONTAINER */
.ticker-wrap {
  width: 100%;
  padding-left: 100%; /* Push contents to right side of screen */
  background-color: #271422;
  padding-top: 2px;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(to left, #fe6d72 1%, #fe6dda 50%, #fe6d72 100%) 
                100% 0 0% 0/1px 0 1px 0 stretch;
}

/* INNER CONTAINER */
@keyframes ticker {
  0% { transform: translate3d(0, 0, 0); }
  100% { transform: translate3d(-100%, 0, 0); }
}
.ticker-move {
  /* Basically move items from right side of screen to left in infinite loop */
  display: inline-block;
  white-space: nowrap;
  padding-right: 100%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ticker;
  animation-duration: 60s;
}
.ticker-move:hover{
  animation-play-state: paused; /* Pause scroll on mouse hover */
}

/* ITEMS */
.ticker-item{
  display: inline-block; /* Lay items in a horizontal line */
  padding: 0 2rem;
}

.content-table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

@media only screen and (max-width: 768px) {
  .content-table tr {    display: table;  width:100%;    }               
  .content-table th {    display: table-row-group;  }        
}

.content-middle-table {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.content-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.btn-active {background-image: linear-gradient(to right, #fe6dda 0%, #f7a597  51%, #fe6dda  100%)}
.btn-active {
   margin: 10px;
   padding: 10px;
   font-size: 16px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-weight: bold;
   color: white;            
   border-radius: 10px;
   width: 260px;
   height: 80px;
   border: none;
   transition: transform 0.1s ease-in-out;
 }

.btn-active:active { 
  transform: scale(0.95); 
} 

.btn-active:hover {
  background-position: right center; 
  color: #fff;
  text-decoration: none;
}

 .btn-main-net {background-image: linear-gradient(to right, #1a2ca3 0%, #1634fb  51%, #1a2ca3  100%)}
.btn-main-net {
  margin-top: 10px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  font-size: 16px;
  font-weight: bold;
  color: white;            
  border-radius: 10px;
  width: 300px;
  border: none;
 }

 .btn-main-net:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .btn-arbitrum-one {background-image: linear-gradient(to right, #c86b00 0%, #d08841  51%, #8a6846  100%)}
 .btn-arbitrum-one {
   margin-top: 10px;
   padding: 15px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-size: 16px;
   font-weight: bold;
   color: white;            
   border-radius: 10px;
   width: 300px;
   border: none;
  }
 
  .btn-arbitrum-one:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  } 

 .btn-xdai {background-image: linear-gradient(to right, #21921f 0%, #04d700  51%, #21921f  100%)}
.btn-xdai {
  margin-top: 10px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  font-size: 16px;
  font-weight: bold;
  color: white;            
  border-radius: 10px;
  width: 300px;
  border: none;
 }

 .network-account {
  background: rgb(83, 31, 68);
  color: white;
  font-weight: bold;
  border-radius: 25px;
  display: inline-block;
  padding: 10px;
 }

 .btn-xdai:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }


 .btn-xdai-network {background-image: linear-gradient(to right, #b14400 0%, #bb642f  51%, #b14400  100%)}
.btn-xdai-network {
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  font-size: 16px;
  font-weight: bold;
  color: white;            
  border-radius: 10px;
  width: 300px;
  border: none;
 }

 .filter-box {
   background:rgb(83, 31, 68);
   color: white;
   border-radius: 14px;
   padding-left: 20px; 
   padding-top: 5px; 
   padding-bottom: 5px; 
   height: 20px;
   border: none;
 }

 .btn-xdai-network:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

.btn-withdraw {background-image: linear-gradient(to right, #fe6dda 0%, #f7a597  51%, #fe6dda  100%)}
.btn-withdraw {
   margin: 10px;
   padding: 10px;
   font-size: 16px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-weight: bold;
   color: white;            
   border-radius: 10px;
   width: 260px;
   height: 80px;
   border: none;
 }

 .btn-withdraw:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .three-col {
   width: 33%
 }

.btn-harvest {background-image: linear-gradient(to right, #68c47c 0%, #6191c6  51%, #68c47c  100%)}
.btn-harvest {
   margin: 10px;
   padding: 10px;
   font-size: 16px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-weight: bold;
   color: white;            
   border-radius: 10px;
   width: 260px;
   height: 80px;
   display: block;
   float: middle;
   border: none;
 }

 .btn-harvest:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .harvest-table {
   margin-left: auto;
   margin-right: auto;
   width: 80%;
   padding: 0px;
 }

 .stake-header {
   color: #fe6dda;
   font-weight: bold;
 }

 .stake-your-info-header {
  color: #f7a597;
  font-weight: bold;
}

 @media only screen and (max-width: 768px) {
  .harvest-table tr {    display: table;  width:100%;    }               
  .harvest-table th {    display: table-row-group;  }        
}

 .rate-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0px;
}

.rate-table th {
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  .rate-table tr {   display: table;  width:100%;    }               
  .rate-table th {   display: table-row-group;  }     
}

 .rate-number {
  font-size: 40px;
  font-weight: bold;
 }

 .harvest-number {
  font-size: 70px;
  font-weight: bold;
 }

 .staking-table {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
}

.staking-table th {
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  .staking-table tr {    display: table;  width:100%;    }               
  .staking-table th {    display: table-row-group;  }        
}

.staking-number {
  font-weight: bold
} 

.btn-disable {background-image: linear-gradient(to right, #818181 0%, #404040  51%, #818181  100%)}
.btn-disable {
   margin: 10px;
   padding: 15px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-weight: bold;
   font-size: 16px;
   color: white;            
   border-radius: 10px;
   width: 300px;
   display: block;
   border: none;
 }

 .btn-disable-track {background-image: linear-gradient(to right, #818181 0%, #404040  51%, #818181  100%)}
.btn-disable-track {
   margin-top: 10px;
   padding: 15px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-weight: bold;
   font-size: 16px;
   color: white;            
   border-radius: 10px;
   width: 300px;
   border: none;
 }

.spacer-row {
  margin-bottom: 50px;
}

.how-to-table {
  margin-top: 5vmin;
  margin-left: 20px;
}

@media only screen and (max-width: 768px) {
  .how-to-table tr {    display: table;  width:100%;    }               
  .how-to-table th {    display: table-row-group;  }        
}

.donut-table {
  margin-top: 5vmin;
  margin-left: 20px;
  margin-left: auto;
  margin-right: auto;
}

.topic-table {
  margin-left: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  table-layout: fixed;
}

a {
  color: #fe6d72;
  text-decoration: none;
}

.title-text {
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

.code {
  text-align: left;
  margin: 20px;
  margin-left: 70px;
  padding: 15px 30px 15px 30px;
  font-family: monospace;
  background-color: #180f16;
  border: 1px solid white;
  display: inline-block;
}

.left-body {
  text-align: left;
  margin: 20px;
  background-color: #271422;
}

.left-body-large {
  text-align: left;
  margin: 20px;
  background-color: #271422;
  font-size: 22px;
  display: inline-block;
}


.faq-odd {
  text-align: left;
  margin: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 35px;
  background-color: #232529;
  border:1px solid rgb(255,182,222)
}

.faq-even {
  text-align: left;
  margin: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 35px;
  background-color: #3e434b;
  border:1px solid rgb(255,182,222)
}

.faq-q {
  font-weight: bold;
  font-size: 18px;
  color: rgb(255,182,222);
}

.faq-a {
  word-wrap: break-word;
}

.container-orig {
  width: 100%;
  background-color: #271422;
  margin: auto;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(to left, #fe6d72 1%, #fe6dda 50%, #fe6d72 100%) 
                100% 0 100% 0/1px 0 1px 0 stretch;
}

.content {
  margin: auto;
  width: 65%;
  background-color: #271422;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  .content {
    width: 95%;
  }
}

.container-middle {
  width: 100%;
  background-color: #271422;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(to left, #fe6d72 1%, #fe6dda 50%, #fe6d72 100%) 
                100% 0 100% 0/1px 0 1px 0 stretch;
}

.content-middle {
  margin: auto;
  width: 65%;
}

@media only screen and (max-width: 768px) {
  .content-middle {
    width: 95%;
  }
}

.left-body-middle {
  text-align: left;
  margin: 20px;
}

.distribute-header {
  text-align: right;
}

.distribute-body {
  font-weight: normal;
  text-align: left;
  padding-left: 10px;
}

.distribute-body-burn {
  font-weight: normal;
  text-align: left;
  padding-left: 25px;
}

.howToImg {
  margin: auto;
  width: 65%;
  padding-top: 10px;
}

.howToImgMetamask {
  margin: auto;
  padding-top: 10px;
}

@media only screen and (max-width: 768px) {
  .howToImgMetamask {
    width: 95%;
  }
}

.break-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  height: 50px;
  text-align: left;
  width: 100%;
  background-color: #271422;
}

.links-container {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 100%;
}

.links {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 60%;
}

@media only screen and (max-width: 768px) {
  .links {
    display: none;
  }
}

.header {
  font-weight: normal;
  padding-top: 15px;
  padding-left: 50px; 
  padding-right: 50px;
}

.moderator {
  color: rgb(255,182,222);
}

.donut-header {
  font-weight: bold;
  padding-top: 15px;
  padding-left: 50px; 
  padding-right: 50px;
}

.contentColumn {
  font-weight: normal;
}

.caption-heading {
  font-weight: bold;
  font-size: 18px;
}

.heading {
  font-weight: bold;
  font-size: 24px;
}

.faq-title {
  font-weight: bold;
  font-size: 18px;
  color: #fe6d72;
  text-align: left;
}

.caption-body {
  font-weight: normal;
  font-style: italic;
  color:rgb(175, 175, 175);
  margin: auto;
  width: 80%;
}

.caption-link {
  font-weight: normal;
}

.footer-text {
  font-weight: normal;
  font-style: italic;
  color:rgb(175, 175, 175)
}

.logo-column {
  font-weight: normal;
  vertical-align: top;
  width: 20%;
  padding-left: 20px; 
  padding-right: 20px;
}

@media only screen and (max-width: 768px) {
  .logo-column {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}

.numColumn {
  padding-top: 21px;
  color: #7e7e7e;
  width: 10%;
  vertical-align: top;
}

.App {
  text-align: center;
  background-color: #271422;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-top: 15px;
  margin-right: 15px;
  height: 200px;
}

@media only screen and (max-width: 768px) {
  .App-logo {
    height: 128px;
    width: 128px;
    pointer-events: none;
    margin-top: 15px;
    margin-right: 15px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 40s linear;
  }
}

.donut-dashboard-logo {
  width: 520px;
}

@media only screen and (max-width: 768px) {
  .donut-dashboard-logo {
    width: 60%;
  }
}

.logo-image {
  height: 30px;
  margin-top: 15px;
}

@media only screen and (max-width: 768px) {
  .logo-image {
    width: 95%;
    height: 20px;
  }
}

.splash-image {
  max-width: 80%;
  border: 2px solid rgb(255,182,222);
}

@media only screen and (max-width: 768px) {
  .splash-image {
    width: 95%;
  }
}

.logo-image-large {
  height: 160px;
  width: 160px
}

@media only screen and (max-width: 1000px) {
  .logo-image-large {
    width: 100px;
    height: 100px;
  }
}

.logo-image-medium {
  height: 100px;
  width: 100px
}

.inline-block {
  display: inline-block;
}

.header-style {
  text-align: center;
  background-color: #271422;
  margin-bottom: 20px;
  color: white;
}

.body-style {
  text-align: center;
  background-color: #271422;
  color: white;
}

.App-link {
  color: #61dafb;
}

.delicious-donuts-column {
  display:table-cell;
  width: 20%;
  vertical-align: top;
}

@media only screen and (max-width: 768px) {
  .delicious-donuts-column {
    display: block;
    width: 100%;
  }
}

.delicious-donut-image-yes {
  width: 100%
}

.delicious-donut-image-no {
  width: 100%;
  opacity: 0.4;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
   -ms-filter: grayscale(100%);
       filter: grayscale(100%);
}

.text-delicious-donut-caption {
  font-weight: bold;
  font-size: 18px;
}

.text-not-collected {
  color:rgb(175, 0, 0);
}

.text-collected {
  color:rgb(0, 180, 6);
}

.text-rarity-common {
  color:rgb(155, 155, 155);
  font-size: 10px;
}

.text-rarity-uncommon {
  color:rgb(150, 247, 126);
  font-size: 10px;
}

.text-rarity-rare {
  color:rgb(194, 0, 158);
  font-size: 10px;
}

.text-alert {
  color:rgb(224, 0, 0);
  font-size: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popup-content {
  width: 600px;
  margin: 10px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: white;    
  background:rgb(83, 31, 68);        
  border-radius: 10px;
  display: block;
  float: middle;
  border-style: solid;
  border-width: 1px;
  border-color: #fe6dda;
}

@media only screen and (max-width: 768px) {
  .allourdonut-img {
    width: 90%
  }
}

.pop-up-btn {background-image: linear-gradient(to right, #68c47c 0%, #6191c6  51%, #68c47c  100%)}
.pop-up-btn {
   margin: 10px;
   padding: 10px;
   font-size: 16px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   font-weight: bold;
   color: white;            
   border-radius: 10px;
   width: 210px;
   height: 50px;
   float: middle;
   border: none;
 }

 .pinkText {
  color: #fe6ddb
 }

 .whiteText {
  color: white;
 }


 .orangeText {
  color: #f7a597
 }

 .lightPinkText {
  color: rgb(255,182,222);
 }

 .darkPinkText {
  color: #893876
 } 

 .grayText {
  color: #7d7d7d
 } 

 .boldText {
  font-weight: bold;
 } 


/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  max-width: 1500px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 600px;
  width: 100%;
  border-radius: 40px;
}

@media only screen and (max-width: 768px) {
  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
  }

  .slide {
    display: inline-block;
    width: 100%;
    height: calc(100vw / 2);
    background-position: center;
    background-size:100% 100%; 
    border-radius: 40px;
    margin:0 auto;
  }
}

@media (min-width: 769px) and (max-width: 2000px) {
  .slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: 1000px;
    height: 500px;
    max-width: 100%;
  }

  .slide {
    display: inline-block;
    width: 100%;
    width: 1000px;
    height: auto;
    height: 500px;
    background-position: center;
    background-size:100% 100%; 
    border-radius: 40px;
    margin:0 auto;
  }
}

.icon-outline-layer {
  fill: white;
  transform: scale(0.75);
  transform-origin: center;
  opacity: 0.9;
}

.icon-background-layer {
  fill: url(#id0);
}

.exchange-listing {
  width: 100%;
  height: 100%;
  max-width: 160px;
  margin: auto;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  border-style: none;
  width: 80%;
  margin: auto;
  position: relative;
  z-index: 1;
  background: #271422;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color:rgb(83, 31, 68);
  color: white;
  cursor: pointer;
  padding: 18px;
  margin: auto;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: rgb(98, 41, 82);
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
  width: 80%;
  margin: auto;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}



/* Governance Proposals */

.card-container {
  display: flex; 
  justify-content: space-evenly; 
  flex-wrap: wrap; 
  column-gap: 10px;
}

.card {
  width: 300px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin: 8px;
  background-color: rgb(54, 27, 46);
  min-height: 210px; 
}

.card .title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to two lines */
  -webkit-box-orient: vertical;
  max-height: 5em; /* Adjust this based on your font size and line height */
  min-height: 70px;
  
}

.card .status {
  font-weight: bold;
  float: left;
  text-transform: capitalize;
}

.card .creation-date {
  font-weight: bold;
  float: right;
}

.card .description {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to two lines */
  -webkit-box-orient: vertical;
  max-height: 6em; 
  margin-bottom: 10px;
}

.card .bar-graph {
  align-items: center;
}

.card .bar-graph-label {
  color: white;
  text-align: left;
  font-weight: bold;
}

.card .bar {
  height: 20px;
  color: white;
  margin-right: 5px;
  margin-bottom: 7px;
}

.card .disclaimer {
  font-style:italic;
  color: gray;
  font-size: 10px;
}

/* Post of the Week */

.post {
  display: flex;
  width: 70%;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 5px;
}

.post:nth-child(1) {
  background-color: #46273d;
}
.post:nth-child(2) {
  background-color: #2f1a2a;
}
.post:nth-child(3) {
  background-color: #271422;
}
.post:nth-child(4) {
  background-color: #180b14;
}

.post-img {
  flex: 1;
  max-width: 20%; /* 1/5 of the width */
  text-align: center; /* Center the image */
  align-items: center;
  display: flex;
}

.post-img img {
  max-width: 80%;
  height: auto; /* Maintain aspect ratio */
}

.post-rank {
  flex: 1;
  max-width: 20%; /* 1/5 of the width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-rank-text {
  vertical-align: middle;
  font-size: 4em; 
  padding: 10px;
  border-radius: 10px;
  animation: pulse 4s infinite;
}

.post-rank-1 {
  color: #e5af0d;
  border: #e5af0d 3px solid;
}
.post-rank-2 {
  color: #b2b2b2;
  border: #b2b2b2 3px solid;
  animation-delay: 1s;
}
.post-rank-3 {
  color: #c17158;
  border: #c17158 3px solid;
  animation-delay: 2s;
}
.post-rank-4 {
  color: #8843b0;
  border: #8843b0 3px solid;
  animation-delay: 3s;
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
      box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.small-donut {
  height: 18px;
  vertical-align: top;
}

.post-right {
  flex: 3;
  max-width: 60%; /* 3/5 of the width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px; 
}

.post-right-content {
  flex: 3;
  display: flex;
  width: 100%;
}

.post-avatar {
  max-width: 15%; 
  min-width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px; 
}

.post-text {
  max-width: 50%; 
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px; 
}

.post-image {
  max-width: 30%; 
  min-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px; 
}

.post-actual-image {
  width: 100%;
}

.post-title {
  width: 100%;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  max-height: 5em; /* Adjust this based on your font size and line height */
  min-height: 70px;
  color: white;
}

.post-text .author {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1080deg); /* Rotate 3 full turns */
  }
}

.spin {
  animation: spin 8s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
}

@media (max-width: 600px) {
  .post {
    flex-direction: column; 
  }
  .post-img {
    flex: none; 
    align-self: center;
    width: 100%; 
  }
  .post-rank {
    flex: none; 
    align-self: center;
    width: 100%; 
  }
  .post-right {
    flex-direction: column; 
    align-self: center; 
    width: 100%; 
    max-width: 100%;
  }
  .post-right-content {
    flex-direction: column;
    padding-top: 30px;
  }
  .post-img img {
    width: 150px;
    max-width: 100%;
  }
  .post-title {
    font-size: 20px;
  }
  .post-avatar {
    align-self: center;
    padding: 20px;
  }
  .post-text {
    align-self: center;
    width: 100%;
    max-width: 100%;
  }
  .post-image {
    align-self: center;
  }
}

.membership-nft-image {
  height: 500px;
}

.membership-label {
  color: #fe6ddb;
  font-weight: bolder;
}

.purchase-box {
  background:rgb(83, 31, 68);
  color: white;
  border-radius: 14px;
  padding-left: 20px; 
  padding-top: 5px; 
  padding-bottom: 5px; 
  height: 24px;
  font-size: 18px; 
  width: 450px;
  border: none;
}

.errorText {
  color: rgb(203, 28, 19);
  font-weight: bold;
  font-size: 18px;
}

:root {
  --clr-1: rgb(51, 26, 44);
  --clr-2: rgb(83, 29, 68);
  --clr-3: rgb(155, 27, 118);
  --clr-4: rgb(187, 3, 141);
  --clr-5: #f7a597;
}

.membership-card {
  flex-wrap: wrap;
  width: 50%;
  position: relative;
  z-index: 1;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 3px solid #0000;
  border-radius: 20px;
  background: linear-gradient(rgb(51, 26, 44), rgb(51, 26, 44)) padding-box, linear-gradient(
        var(--angle),
        rgb(187, 3, 141),
        #f7a597
      ) border-box;
  animation: 8s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.topicText {
  font-size: 24px;
}

.exclamation {
  margin-left: 5px;
  margin-right: 5px;
  font-size: 24px;
}

.topic-row-full {
  background: #5d2a50;
}

.topic-row-almost-full {
  background: #47263f;
}

.topic-image {
  width: 50%;
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(254deg) brightness(95%) contrast(80%);
  max-width: 100px;
}

.topic-image-small {
  width: 40%;
  max-width: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.topic-image-large {
  transform: scale(1.2);
}

.topic-card {
  width: 70%;
  word-break: break-word;
}

.topic-header {
  font-weight: bold;
  padding-top: 15px;
}

@media only screen and (max-width: 768px) {
  .membership-nft-image {
    width: 100%;
    height: auto;
  }
  .purchase-box {
    width: 90%;
  }
  .membership-card {
    width: 90%;
  }
  .topicText {
    font-size: 18px;
  }
}

.contact-form-text {
  font-size: 18px;
}
.contact-form-input {
  font-size: 18px;
  width: 50%;
  max-width: 350px;
  color: white;
  border: rgb(187, 3, 141) 4px solid;
  background: #47263f;
}
.contact-form-msg-input {
  font-size: 18px;
  width: 80%;
  max-width: 700px;
  color: white;
  height: 150px;
  border: rgb(187, 3, 141) 4px solid;
  background: #47263f;
}

/* Basic styles for the navigation */
nav {
  position: relative;
}

/* Menu styles */
.menu {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.menu.mobile {
  display: none;
  flex-direction: column;
}

.menu.open {
  display: flex;
}

.menu-item {
  position: relative;
}

.menu-item a,
.menu-item .dropbtn {
  display: block;
  padding: 16px;
  margin: 15px;
  font-weight: bold;
  text-decoration: none;
  font-size: 17px;
  color: #f2f2f2;
}

.menu-item .active {
  background-color: rgb(255,182,222);
  color: #271422;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  z-index: 1;
  background-color: #271422;
  border: 1px solid #fe6dda;
}

.dropdown-content a {
  padding: 16px;
  text-decoration: none;
  display: block;
  color: #f2f2f2;
  border-bottom: 1px solid #fe6dda;
}

.dropdown-content a:hover {
  background-color: rgb(176, 113, 148);
}

.dropdown-content a:last-child {
  border-bottom: none;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Hamburger menu icon styles */
.mobile-menu-icon {
  display: none;
  cursor: pointer;
  color: #f2f2f2;
  padding: 10px;
}

.hamburger-icon span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px;
  background-color: #f2f2f2;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .menu {
    display: none;
    flex-direction: column;
    background-color: #271422;
    width: 100%;
  }
  .menu.open {
    display: flex;
  }
  .menu.desktop {
    display: none;
  }
  .mobile-menu-icon {
    display: block;
  }
  .menu-item {
    width: 100%;
  }
  .menu-item a,
  .menu-item .dropbtn {
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
    
  }
  .menu-item .dropbtn {
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
    color: #fe6dda; 
    border-top: 1px solid #fe6dda;
    border-bottom: 1px solid #fe6dda;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .dropdown-content {
    position: relative;
    border: none;
  }

  .dropdown-content a {
    border: none;
  }
  /* Disable hover effect on mobile */
  .dropdown:hover .dropdown-content {
    display:block;
  }
  /* Show sub-menus in mobile menu */
  .dropdown-content {
    display: block;
  }
  .dropdown-content a:hover {
    background-color: rgb(176, 113, 148);
  }
}
 